import Vue from 'vue';
import Router from 'vue-router';
import HomeView from '../views/Home.vue';

Vue.use(Router);

export const constantRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/planDetail',
    name: 'planDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PlanDetail.vue'),
  },
  {
    path: '/productDetail/1',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/DoublePrevention.vue'),
  },
  {
    path: '/productDetail/2',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Inspection.vue'),
  },
  {
    path: '/productDetail/3',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Equipment.vue'),
  },
  {
    path: '/productDetail/4',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Danger.vue'),
  },
  {
    path: '/productDetail/5',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SpecialWork.vue'),
  },
  {
    path: '/productDetail/6',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Train.vue'),
  },
  {
    path: '/productDetail/7',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PersonPosition.vue'),
  },
  {
    path: '/productDetail/8',
    name: 'productDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Technology.vue'),
  },
  // {
  //   path: '/productDetail/10',
  //   name: 'productDetail',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/Close.vue'),
  // },
];

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});
