<template>
  <div class="home">
    <!-- 头部导航 -->
    <div class="header" :class="scrollTop > 10 ? 'header-scrolled' : ''">
      <div class="logo" v-if="scrollTop < 10">
        <img class="img" src="@/assets/logo.png" />
        <img class="img" src="@/assets/name_logo.png" />
      </div>
      <div class="logo" v-else>
        <img class="img" src="@/assets/logo2.png" />
        <img class="img" src="@/assets/name_logo2.png" />
      </div>
      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active"><a href="#.html">首页</a></li>
          <li><a href="#gywm">关于我们</a></li>
          <li><a href="#gsfw">公司服务</a></li>
          <li><a href="#jjfa">解决方案</a></li>
          <li><a href="#cpzx">产品中心</a></li>
          <li><a href="#hzkh">合作客户</a></li>
          <li><a href="#ryzz">荣誉资质</a></li>
          <li><a href="#lxwm">联系我们</a></li>
        </ul>
      </nav>
    </div>

    <!-- 轮播图 -->
    <div class="banner">
      <el-carousel height="100vh">
        <el-carousel-item class="item1">
          <div class="content">
            <h2 style="color: #606266">助力企业数字化智能转型升级</h2>
            <p style="color: #606266">携手打造物联网生态平台</p>
          </div>
        </el-carousel-item>
        <el-carousel-item class="item2">
          <div class="content">
            <h2>人工智能助力产品升级</h2>
            <p>打造电力、化工等全方位解决方案</p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 关于我们 -->
    <div class="us" id="gywm">
      <div class="title">
        <p>武汉市浅蓝网络科技有限公司</p>
      </div>
      <div class="content">
        <p>
          武汉市浅蓝网络科技有限公司于2011年成立于湖北武汉，国家高新技术企业；一直专注于工业互联网平台及行业应用产品技术研发与服务，为应急安全、智慧化工、智慧园区、智慧电厂、行业数字化转型等提供整体解决方案。
        </p>
      </div>
      <div class="images">
        <div class="item">
          <img src="@/assets/img/gywm/pic-3.jpg" alt="" />
        </div>
        <div class="item1">
          <img src="@/assets/img/gywm/pic-2.jpg" alt="" />
          <img src="@/assets/img/gywm/pic-1.jpg" alt="" />
        </div>
        <div class="item2">
          <img src="@/assets/img/gywm/pic-4.jpg" alt="" />
        </div>
      </div>
      <div class="content">
        <p>
          公司以高效务实为理念打造团队。创始成员具有丰富的一线公司高管经验和创业经历，核心成员磨合近10年，同时引进了多家上市科技公司的行业高端人才。是一支具有强大的研发、分析、设计及实施能力的高素质研发团队。
        </p>
      </div>
    </div>

    <!-- 公司服务 -->
    <div class="service" id="gsfw">
      <div class="title">
        <p>公司服务</p>
      </div>
      <div class="row">
        <div class="item">
          <img src="@/assets/img/gsfw/fw1.png" />
          <h4>信息化咨询设计服务</h4>
          <p>
            提供应急管理、化工园区及企业领域方案设计、可行性研究报告初步设计、招投标等设计咨询服务
          </p>
        </div>
        <div class="item">
          <img src="@/assets/img/gsfw/fw2.png" />
          <h4>软件研发定制服务</h4>
          <p>
            每一个软件的开发都要经过细致的系统分析，针对不同企业的情况，编制最适用的程序
          </p>
        </div>
        <div class="item">
          <img src="@/assets/img/gsfw/fw3.png" />
          <h4>系统集成服务</h4>
          <p>
            为客户提供监管执法设备、物联感知产品、应急指挥设备与IT信息化软件系统一体化集成服务
          </p>
        </div>
        <!-- <div class="item">
          <img src="@/assets/img/gsfw/fw4.png" />
          <h4>产品供应服务</h4>
          <p>
            提供执法记录仪、移动执法终端、多功能智能终端，视频边缘计算终端、煤矿采集前置机、工业数据与安全网关等产品供应服务
          </p>
        </div> -->
      </div>
    </div>

    <!-- 解决方案 -->
    <div class="plan" id="jjfa">
      <div class="title">
        <p>解决方案</p>
        <div>深入行业，沉淀自海量领先的最佳实践</div>
      </div>
      <div class="row">
        <div class="item" @click="goPlanDetail(1)">
          <img class="img" src="@/assets/img/jjfa/pic-6.png" alt="" />
          <div class="content">
            <p>危险化学品企业安全管控平台</p>
            <!-- <div class="btn">了解详情</div> -->
          </div>
        </div>
        <div class="item" @click="goPlanDetail(2)">
          <img class="img" src="@/assets/img/jjfa/pic-7.png" alt="" />
          <div class="content">
            <p>化工园区安全管控平台</p>
            <!-- <div class="btn">了解详情</div> -->
          </div>
        </div>
        <div class="item" @click="goPlanDetail(3)">
          <img class="img" src="@/assets/img/jjfa/pic-8.png" alt="" />
          <div class="content">
            <p>工贸企业安全管控平台</p>
            <!-- <div class="btn">了解详情</div> -->
          </div>
        </div>
        <div class="item" @click="goPlanDetail(4)">
          <img class="img" src="@/assets/img/jjfa/pic-5.png" alt="" />
          <div class="content">
            <p>智慧社区解决方案</p>
            <!-- <div class="btn">了解详情</div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 产品中心 -->
    <div class="product" id="cpzx">
      <div class="title">
        <p>产品中心</p>
      </div>
      <div class="row">
        <div class="item item-2" @click="goProductDetail(1, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">双重预防管理系统</div>
            <div class="tip">风险分级管控</div>
            <div class="tip">隐患排查治理</div>
            <div class="tip">风险分布四色图</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(2, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">智能巡检管理系统</div>
            <div class="tip">巡检点设置</div>
            <div class="tip">巡检点排查计划</div>
            <div class="tip">巡检记录</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(3, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">设备风险评估系统</div>
            <div class="tip">专业的评价模型</div>
            <div class="tip">直观的管理服务</div>
            <div class="tip">有效的风险服务</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(4, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">重大危险源管理系统</div>
            <div class="tip">监测预警报警</div>
            <div class="tip">风险监测预警设置</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(5, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">特殊作业管理系统</div>
            <div class="tip">动火作业</div>
            <div class="tip">受限空间作业</div>
            <div class="tip">等八个作业管理</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(6, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">安全教育培训管理系统</div>
            <div class="tip">丰富的课程中心</div>
            <div class="tip">灵活便捷的在线考试和自动阅卷</div>
            <div class="tip">全生命周期培训业务管理</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(7, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">人员定位管理系统</div>
            <div class="tip">区域管理</div>
            <div class="tip">报警管理</div>
            <div class="tip">定位管理</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(8, true)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">工艺风险评估系统</div>
            <div class="tip">分析准备</div>
            <div class="tip">节点划分</div>
            <div class="tip">偏差分析</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(9, false)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">变更管理系统</div>
            <div class="tip">变更申请</div>
            <div class="tip">变更审批</div>
            <div class="tip">变更应用及实施</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(10, false)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">智慧封闭化系统</div>
            <div class="tip">AI+封闭</div>
            <div class="tip">GIS+封闭</div>
            <div class="tip">危化停车场智能监控</div>
          </div>
        </div>
        <div class="item" @click="goProductDetail(11, false)">
          <img class="img" src="@/assets/img/cpzx/pic-9.png" alt="" />
          <div class="content">
            <div class="tip-title">园区、企业一张图</div>
            <div class="tip">安全为本，统一监测预警</div>
            <div class="tip">一图到底，统一数据资源</div>
            <div class="tip">科学智能，精准分析研判</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作客户 -->
    <div class="client" id="hzkh">
      <div class="title">
        <p>合作客户</p>
        <div></div>
      </div>
      <div class="row">
        <div class="item">
          <img src="@/assets/img/hzkh/pic-1.jpg" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-2.jpg" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-3.jpg" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-4.jpg" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-5.jpg" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-6.png" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-7.png" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-8.png" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-9.png" alt="" />
        </div>
        <div class="item">
          <img src="@/assets/img/hzkh/pic-10.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 荣誉资质 -->
    <div class="honor" id="ryzz">
      <div class="title">
        <p>荣誉资质</p>
      </div>
      <div class="honor-content">
        <img src="@/assets/img/ryzz/pic-1.png" alt="" />
        <img src="@/assets/img/ryzz/pic-2.png" alt="" />
        <img src="@/assets/img/ryzz/pic-3.png" alt="" />
        <img src="@/assets/img/ryzz/pic-4.png" alt="" />
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="connection" id="lxwm">
      <div class="title">
        <p>联系我们</p>
        <div>
          我们深信，别人做不到的，我们能做到；别人做得到的，我们会做得更好。
        </div>
      </div>
      <div class="row">
        <div class="img">
          <img src="@/assets/img/slider-2.jpg" alt="" />
        </div>
        <div class="content">
          <div class="content-title">武汉市浅蓝网络科技有限公司</div>
          <div class="tel">
            <img src="@/assets/img/lxwm/icon-1.png" alt="" />
            <div class="text">
              <span>全国服务热线</span>
              <span class="num">185 0270 4911</span>
            </div>
          </div>
          <div class="msg">
            <img src="@/assets/img/lxwm/icon-2.png" alt="" />
            <span>地址：湖北省武汉市东湖高新区光谷鼎创国际1栋2004</span>
          </div>
          <div class="msg">
            <img src="@/assets/img/lxwm/icon-3.png" alt="" />
            <span>电话：185 0270 4911</span>
          </div>
          <div class="msg">
            <img src="@/assets/img/lxwm/icon-4.png" alt="" />
            <span>邮箱：service@qlcloud.ltd</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧-联系我们 -->
    <div class="sideBar" :style="scrollTop > 800 ? '' : 'display: none'">
      <div class="side1">
        <a href="#lxwm">
          <img
            src="@/assets/img/connection/icon-1.png"
            alt="contact"
            title="contact"
            la="la"
          />
          <i></i>
          <span>联系我们</span>
        </a>
      </div>
      <div class="side2 side dapc">
        <!--  href="tel:13312345678" -->
        <a>
          <i
            ><img
              src="@/assets/img/connection/icon-2.png"
              alt="tel"
              title="tel"
              la="la"
          /></i>
          <span>185 0270 4911</span>
        </a>
      </div>
      <div class="side2 side das">
        <!-- href="tel:13312345678" -->
        <a>
          <img
            src="@/assets/img/connection/icon-2.png"
            alt="tel"
            title="tel"
            la="la"
          />
          <p>咨询</p>
        </a>
      </div>
      <div class="side5 side">
        <a>
          <i
            ><img
              src="@/assets/img/connection/icon-1.png"
              alt="qcode"
              title="qcode"
              la="la"
          /></i>
          <span>关注我们</span>
        </a>
        <div class="qcodePic animated fadeInUp">
          <img
            src="@/assets/img/connection/icon-1.png"
            alt=""
            title=""
            la="la"
          />
        </div>
      </div>
      <div class="side6 side">
        <a href="#.html">
          <i
            ><img
              src="@/assets/img/connection/icon-3.png"
              alt="top"
              title="top"
              la="la"
          /></i>
          <span>返回顶部</span>
        </a>
      </div>
    </div>

    <!-- 产品提示弹框 -->
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <div class="pro-close">
        <img src="@/assets/img/pro-close.png" alt="" />
        <span>正在加急设计ing</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Footer from './Footer.vue';
export default {
  name: 'Home',
  components: {
    Footer,
  },
  data() {
    return {
      scrollTop: null,
      dialogVisible: false,
    };
  },
  watch: {
    scrollTop: {
      handler(val) {
        // console.log(val);
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    //获取滚动高度
    handleScroll() {
      this.$nextTick(() => {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        this.scrollTop = scrollTop;
      });
    },
    goPlanDetail(id) {
      // let routeUrl = this.$router.resolve({
      //   path: '/planDetail',
      //   query: { id },
      // });
      // window.open(routeUrl.href, '_blank');
    },
    goProductDetail(id, isFalse) {
      if (!isFalse) {
        this.dialogVisible = true;
      } else {
        let routeUrl = this.$router.resolve({
          path: `/productDetail/${id}`,
          // query: { id },
        });
        window.open(routeUrl.href, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//头部导航
@import '~@/assets/styles/header.scss';
//菜单导航
@import '~@/assets/styles/nav.scss';
//轮播
@import '~@/assets/styles/banner.scss';
//关于我们
@import '~@/assets/styles/us.scss';
//公司服务
@import '~@/assets/styles/service.scss';
//解决方案
@import '~@/assets/styles/plan.scss';
//产品中心
@import '~@/assets/styles/product.scss';
//合作客户
@import '~@/assets/styles/client.scss';
//荣誉资质
@import '~@/assets/styles/honor.scss';
//联系我们
@import '~@/assets/styles/connection.scss';
//右侧-联系我们
@import '~@/assets/styles/sideBar.scss';
//适配
@import '~@/assets/styles/media.scss';
.pro-close {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
