<template>
  <div class="footer">
    <div class="nav">
      <div class="logo">
        <img class="img" src="@/assets/logo2.png" />
        <img class="img" src="@/assets/name_logo2.png" />
      </div>
      <nav id="footer-nav">
        <ul class="nav-menu">
          <li class="menu-active"><a href="#.html">首页</a></li>
          <li><a href="#gywm">关于我们</a></li>
          <li><a href="#gsfw">公司服务</a></li>
          <li><a href="#jjfa">解决方案</a></li>
          <li><a href="#cpzx">产品中心</a></li>
          <li><a href="#hzkh">合作客户</a></li>
          <li><a href="#ryzz">荣誉资质</a></li>
          <li><a href="#lxwm">联系我们</a></li>
        </ul>
      </nav>
    </div>

    <div class="msg">
      <div class="item">
        <img src="@/assets/img/footer/icon-2.png" alt="">
        <div class="title">公司地址</div>
        <div class="content">湖北省武汉市东湖高新区光谷鼎创国际1栋2004</div>
      </div>
      <div class="item">
        <img src="@/assets/img/footer/icon-3.png" alt="">
        <div class="title">公司电话</div>
        <div class="content">185 0270 4911</div>
      </div>
      <div class="item">
        <img src="@/assets/img/footer/icon-4.png" alt="">
        <div class="title">公司邮箱</div>
        <div class="content">service@qlcloud.ltd</div>
      </div>
    </div>

    <div class="ltd">Copyright © 2019-2029 武汉浅蓝网络科技有限公司 版权所有 备案号：鄂ICP备2023000622号-1</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  height: 380px;
  background: #2d529b;
  padding: 0 15%;
  .nav {
    background: #2d529b;
    padding: 15px 30px 0 30px;
    height: 72px;
    transition: all 0.3s;
    border-bottom: 1px solid #4a7dbc;
    display: flex;
    align-items: center;
    .logo {
      height: 50px;
      display: flex;
      .img {
        padding: 0;
        margin: 0;
        max-height: 100%;
      }
    }
  }

  #footer-nav {
    margin: 0;
    padding-top: 15px;
    .nav-menu {
      margin: 0;
      padding: 0;
      list-style: none;
      ul {
        position: absolute;
        display: none;
        top: 100%;
        left: 0;
        z-index: 99;
        margin: 4px 0 0 0;
        padding: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        background: #ffffff;
      }
      li {
        position: relative;
        white-space: nowrap;
        margin-left: 10px;
        transition: 0.3s;
        float: left;
        text-align: center;
        min-width: 85px;
        a {
          padding: 5px 10px;
          transition: 0.3s;
          display: block;
          font-size: 14px;
          color: #ffffff;
          text-transform: none;
          text-decoration: none;
        }
      }
    }
  }

  .msg{
    display: flex;
    justify-content: space-between;
    color: #fff;
    border-bottom: 1px solid #4a7dbc;
    .item{
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px;
      img{
        width: 80px;
        height: 80px;
      }
      .title{
        font-size: 20px;
        margin: 20px;
        font-weight: bold;
      }
    }
  }

  .ltd {
    padding: 19px 0 22px;
    color: #dfdfdf;
    font-size: 14px;
    border-top: 1px solid #1b2c3d;
    text-align: center;
  }
}
@media (max-width: 1485px) {
  .footer{
    height: auto !important;
  }
  .nav{
    border: none !important;
  }
}
@media (max-width: 768px) {
  .nav{
    height: auto !important;
    border: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .msg{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .item{
      width: 100% !important;
    }
  }
}
</style>
